import { HttpClient } from "@angular/common/http";
import { Component, Inject, OnInit } from "@angular/core";
import { AngularFireAuth } from "@angular/fire/compat/auth";
import { AngularFirestore } from "@angular/fire/compat/firestore";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { ApiService } from "src/app/services/api/api.service";
import { StateService } from "src/app/services/shared/state.service";
import { PlanInfo } from "src/app/models/planInfo";
import { Router } from "@angular/router";
import { environment } from "src/environments/environment";
import { StaticDataService } from "src/app/services/shared/static.data.service";
import { GetPaymentPlans } from "src/app/models/getPaymentPlans";

@Component({
  selector: "app-premium-subscription",
  templateUrl: "./premium-subscription.component.html",
  styleUrls: ["../login/login.component.scss"],
})
export class PremiumSubscriptionComponent implements OnInit {
  // @ts-ignore
  paddle = Paddle;
  selectedPlanIndex: number = 0; // 0 - monthly, 1 - yearly
  selectedPlanName: string = '';
  selectedPlanSubtotal: number = 0;
  selectedPlanTotal: number = 0;
  selectedPlanTax: number = 0;
  isLoading: boolean = true;
  email: string | null = null;
  userId: string | null = null;
  availablePlans?: GetPaymentPlans;
  monthlyChecked: boolean = true;
  yearlyChecked: boolean = false;
  currencyCode: string = ''
  selectedPlan: string = 'monthly';
  popoverTexts = [
    {
      title: 'Full access: 21M+ ads, 5M+ landers',
      text: 'Access VidTao’s database of 21.4M YouTube ads, 5.1M landers, and 4.8M advertisers to uncover what works in your industry.'
    },
    {
      title: 'Full access to performance metrics',
      text: 'Access insights like total ad spend, spend over 30/90 days, and ad spend trends. View charts for spend over time at ad, brand, and advertiser levels, plus identify top-performing ads to inform your strategy.'
    },
    {
      title: 'Advanced ad sorting options',
      text: 'Sort and filter ads by advanced parameters like ad spend (highest to lowest) to quickly identify the most successful campaigns in your niche.'
    },
    {
      title: 'Exclusive VidTao Community',
      text: 'Join the private VidTao Discord group to connect with top YouTube advertisers, share tips, and get feedback from experts.'
    },
    {
      title: 'YouTube ads training library',
      text: 'Master YouTube ads and marketing with 150+ trainings on copywriting, creatives, media buying, tracking, and strategy. Learn to optimize campaigns, analyze performance, and use AI tools to boost results.'
    },
    {
      title: 'Direct to YouTube linking',
      text: 'Seamlessly share ads, view engagement metrics, and optimize workflows across your team with direct YouTube links.'
    },
    {
      title: 'Priority feature access',
      text: 'Yearly members receive priority consideration for feature requests and access to new features.'
    },
    {
      title: 'Annual discount: save 19%',
      text: 'Save 19% compared to the Monthly plan—or $157 annually—by choosing the Yearly plan (best value).'
    }
  ]


  constructor(
    public dialogRef: MatDialogRef<PremiumSubscriptionComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public apiService: ApiService,
    public router: Router,
    private afAuth: AngularFireAuth,
    public stateService: StateService,
    public staticDataService: StaticDataService
  ) {
    this.availablePlans = this.staticDataService.paymentPlans;

  }


  async ngOnInit() {
    this.afAuth.currentUser.then((user) => {
      if (user) {
        this.email = user.email;
        this.userId = user.uid;
        this.OpenSubscriptionsCheckout();
      }
    });
  }

  public SelectPlan(plan: string) {
    if (plan === "monthly") {
      this.SwitchPlan(0);
      this.selectedPlan = 'monthly';
      this.monthlyChecked = true;
      this.yearlyChecked = false;
    } else if (plan === "yearly") {
      this.SwitchPlan(1);
      this.selectedPlan = 'yearly';
      this.monthlyChecked = false;
      this.yearlyChecked = true;
    }
  }

  public CalculateMonthlyPrice(yearlyPrice: number): number {
    return Math.ceil(yearlyPrice / 12);
  }

  public OpenSubscriptionsCheckout() {
    let itemsList = [
      this.selectedPlanIndex === 0 ?
        {
          priceId: this.availablePlans?.monthly.planPriceId,
          quantity: 1
        } : {
          priceId: this.availablePlans?.yearly.planPriceId,
          quantity: 1
        }
    ];
    this.paddle.Update({
      eventCallback: (event: any) => this.HandlePaddleEvent(event),  // Use arrow function
    });
    this.paddle.Checkout.open({
      items: itemsList,
      customData: {
        userId: this.userId,
        email: this.email
      },
      customer: {
        email: this.email
      }
    });
  }

  HandlePaddleEvent(event: any) {
    switch (event.name) {
      case "checkout.loaded":
        this.isLoading = false;
        this.selectedPlanName = event.data.items[0].product.name;
        this.selectedPlanSubtotal = event.data.items[0].totals.subtotal;
        this.selectedPlanTotal = event.data.items[0].totals.total;
        this.selectedPlanTax = event.data.items[0].totals.tax;
        this.currencyCode = event.data.currency_code;
        break;
      case "checkout.completed":

        this.GetUpdatedUser(event);
        break;
    }
  }

  SwitchPlan(index: number) {
    this.isLoading = true;
    this.selectedPlanIndex = index;
    let itemsList = [
      this.selectedPlanIndex === 0 ?
        {
          priceId: this.availablePlans?.monthly.planPriceId,
          quantity: 1
        } : {
          priceId: this.availablePlans?.yearly.planPriceId,
          quantity: 1
        }
    ];
    this.paddle.Checkout.open({
      items: itemsList,
      customData: {
        userId: this.userId,
        email: this.email
      },
      customer: {
        email: this.email
      }
    });
  }

  public async GetUpdatedUser(event: any) {
    var user = await this.apiService.SubscribeUser(event);
    while (user?.data?.plan === "free") {
      await this.Sleep(200);
      user = await this.apiService.SubscribeUser(event);
    }
    this.stateService.UpdateUser(user?.data);
    this.CloseModal();
  }

  Sleep(milliseconds: number): Promise<void> {
    return new Promise<void>((resolve) => {
      setTimeout(resolve, milliseconds);
    });
  }

  public CloseModal() {
    this.dialogRef.close();
  }
}




































// this.paddle.Checkout.open({
//   method: "inline",
//   product: 69593,
//   allowQuantity: false,
//   disableLogout: true,
//   frameTarget: "checkout-container",
//   passthrough: `{"userId": "${userId}"}`,
//   frameInitialHeight: 450,
//   successCallback: async (event: any) => {
//     var user = await this.apiService.SubscribeUser(event);
//     while (user?.data?.plan === "free") {
//       await this.Sleep(200);
//       user = await this.apiService.SubscribeUser(event);
//     }
//     this.stateService.UpdateUser(user?.data);
//     UserPurchasedGtm(subscriptionPlanId);
//     closeModal();
//   },
//   frameStyle:
//     "width:100%; min-width:312px; background-color: transparent; border: none;",
//   ...(email && { email }),
// });