<mat-dialog-content style="padding: 20px !important">
    <div class="d-flex position-relative">
        <img class="mx-auto" style="height: 40px;" src="assets/icons/logo-black-notext.png" />
        <img (click)="CloseModal()" class="my-auto position-absolute" style="height: 24px; right: 5px; cursor: pointer;"
            src="assets/icons/x-close.png" />
    </div>
    <h1 class="premium-title text-center mb-1 mt-4">
        Upgrade to VidTao Premium to Get <u>Unlimited Access</u>To YouTube <u>Ad & Lander Library</u>:
    </h1>
    <div class="d-flex justify-content-center mt-5">
        <app-plan-radio [selectedPlan]="selectedPlan" (onSelectPlan)="SelectPlan($event)"></app-plan-radio>
    </div>
    <div class="row mt-4">
        <div class="col-12 col-lg-6">
            <div class="plan-card" (click)="SelectPlan('monthly')" [ngClass]="monthlyChecked ? 'plan-selected': ''">
                <div class="d-flex align-items-center justify-content-center">
                    <p class="plan-title mb-0">${{availablePlans?.monthly?.price}}/mth</p>
                </div>
                <p class="plan-subtitle mt-2 mb-1 text-center">Premium monthly</p>
                <p class="plan-feature-text text-center">${{availablePlans?.monthly?.price}} + tax, <u>billed
                        monthly</u></p>
                <div class="plan-features mt-2">
                    <div class="d-block">
                        <div class="d-flex align-items-start mb-2">
                            <img style="height: fit-content;" class="mr-2" src="assets/icons/green-check.png" />
                            <p class="plan-feature-text d-flex mb-0 flex-grow-1">Full access: 21M+ ads, 5M+ landers
                            </p>
                            <app-popover-text class="flex-grow-0" [title]="popoverTexts[0].title"
                                [text]="popoverTexts[0].text">
                            </app-popover-text>
                        </div>
                        <div class="d-flex align-items-start mb-2">
                            <img style="height: fit-content;" class="mr-2" src="assets/icons/green-check.png" />
                            <p class="plan-feature-text d-flex mb-0 flex-grow-1">Detailed performance metrics</p>
                            <app-popover-text class="flex-grow-0" [title]="popoverTexts[1].title"
                                [text]="popoverTexts[1].text"></app-popover-text>
                        </div>
                        <div class="d-flex align-items-start mb-2">
                            <img style="height: fit-content;" class="mr-2" src="assets/icons/green-check.png" />
                            <p class="plan-feature-text d-flex mb-0 flex-grow-1">Advanced ad-sorting options</p>
                            <app-popover-text class="flex-grow-0" [title]="popoverTexts[2].title"
                                [text]="popoverTexts[2].text"></app-popover-text>
                        </div>
                        <div class="d-flex align-items-start mb-2">
                            <img style="height: fit-content;" class="mr-2" src="assets/icons/green-check.png" />
                            <p class="plan-feature-text d-flex mb-0 flex-grow-1">Youtube ads training library</p>
                            <app-popover-text class="flex-grow-0" [title]="popoverTexts[4].title"
                                [text]="popoverTexts[4].text"></app-popover-text>
                        </div>
                        <div class="d-flex align-items-start mb-2">
                            <img style="height: fit-content;" class="mr-2" src="assets/icons/unavailable-icon.svg" />
                            <p class="plan-feature-text d-flex mb-0 flex-grow-1">Exclusive VidTao Community membership
                            </p>
                            <app-popover-text class="flex-grow-0" [title]="popoverTexts[3].title"
                                [text]="popoverTexts[3].text"></app-popover-text>
                        </div>
                        <div class="d-flex align-items-start mb-2">
                            <img style="height: fit-content;" class="mr-2" src="assets/icons/unavailable-icon.svg" />
                            <p class="plan-feature-text d-flex mb-0 flex-grow-1">Direct to YouTube linking</p>
                            <app-popover-text class="flex-grow-0" [title]="popoverTexts[5].title"
                                [text]="popoverTexts[5].text"></app-popover-text>
                        </div>
                        <div class="d-flex align-items-start mb-2">
                            <img style="height: fit-content;" class="mr-2" src="assets/icons/unavailable-icon.svg" />
                            <p class="plan-feature-text d-flex mb-0 flex-grow-1">Priority feature access</p>
                            <app-popover-text class="flex-grow-0" [title]="popoverTexts[6].title"
                                [text]="popoverTexts[6].text"></app-popover-text>
                        </div>
                        <div class="d-flex align-items-start mb-2">
                            <img style="height: fit-content;" class="mr-2" src="assets/icons/unavailable-icon.svg" />
                            <p class="plan-feature-text d-flex mb-0 flex-grow-1">Save 19% compared to monthly</p>
                            <app-popover-text class="flex-grow-0" [title]="popoverTexts[7].title"
                                [text]="popoverTexts[7].text"></app-popover-text>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-12 col-lg-6">
            <div class="plan-card" (click)="SelectPlan('yearly')" [ngClass]="yearlyChecked ? 'plan-selected': ''">
                <div class="d-flex align-items-center justify-content-center">
                    <p class="plan-title mb-0">{{CalculateMonthlyPrice(availablePlans?.yearly?.price ?? 0)}}/mth</p>
                </div>
                <p class="plan-subtitle mt-2 mb-1 text-center">Premium yearly</p>
                <p class="plan-feature-text text-center">${{availablePlans?.yearly?.price}} + tax, <u>billed
                        annually</u></p>
                <div class="plan-features mt-2">
                    <div class="d-block">
                        <div class="d-flex align-items-start mb-2">
                            <img style="height: fit-content;" class="mr-2" src="assets/icons/green-check.png" />
                            <p class="plan-feature-text d-flex mb-0 flex-grow-1">Full access: 21M+ ads, 5M+ landers
                            </p>
                            <app-popover-text class="flex-grow-0" [title]="popoverTexts[0].title"
                                [text]="popoverTexts[0].text">
                            </app-popover-text>
                        </div>
                        <div class="d-flex align-items-start mb-2">
                            <img style="height: fit-content;" class="mr-2" src="assets/icons/green-check.png" />
                            <p class="plan-feature-text d-flex mb-0 flex-grow-1">Detailed performance metrics</p>
                            <app-popover-text class="flex-grow-0" [title]="popoverTexts[1].title"
                                [text]="popoverTexts[1].text"></app-popover-text>
                        </div>
                        <div class="d-flex align-items-start mb-2">
                            <img style="height: fit-content;" class="mr-2" src="assets/icons/green-check.png" />
                            <p class="plan-feature-text d-flex mb-0 flex-grow-1">Advanced ad-sorting options</p>
                            <app-popover-text class="flex-grow-0" [title]="popoverTexts[2].title"
                                [text]="popoverTexts[2].text"></app-popover-text>
                        </div>
                        <div class="d-flex align-items-start mb-2">
                            <img style="height: fit-content;" class="mr-2" src="assets/icons/green-check.png" />
                            <p class="plan-feature-text d-flex mb-0 flex-grow-1">Youtube ads training library</p>
                            <app-popover-text class="flex-grow-0" [title]="popoverTexts[4].title"
                                [text]="popoverTexts[4].text"></app-popover-text>
                        </div>
                        <div class="d-flex align-items-start mb-2">
                            <img style="height: fit-content;" class="mr-2" src="assets/icons/green-check.png" />
                            <p class="plan-feature-text d-flex mb-0 flex-grow-1">Exclusive VidTao Community membership
                            </p>
                            <app-popover-text class="flex-grow-0" [title]="popoverTexts[3].title"
                                [text]="popoverTexts[3].text"></app-popover-text>
                        </div>
                        <div class="d-flex align-items-start mb-2">
                            <img style="height: fit-content;" class="mr-2" src="assets/icons/green-check.png" />
                            <p class="plan-feature-text d-flex mb-0 flex-grow-1">Direct to YouTube linking</p>
                            <app-popover-text class="flex-grow-0" [title]="popoverTexts[5].title"
                                [text]="popoverTexts[5].text"></app-popover-text>
                        </div>
                        <div class="d-flex align-items-start mb-2">
                            <img style="height: fit-content;" class="mr-2" src="assets/icons/green-check.png" />
                            <p class="plan-feature-text d-flex mb-0 flex-grow-1">Priority feature access</p>
                            <app-popover-text class="flex-grow-0" [title]="popoverTexts[6].title"
                                [text]="popoverTexts[6].text"></app-popover-text>
                        </div>
                        <div class="d-flex align-items-start mb-2">
                            <img style="height: fit-content;" class="mr-2" src="assets/icons/green-check.png" />
                            <p class="plan-feature-text d-flex mb-0 flex-grow-1">Save 19% compared to monthly</p>
                            <app-popover-text class="flex-grow-0" [title]="popoverTexts[7].title"
                                [text]="popoverTexts[7].text"></app-popover-text>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div style="height: 1px; background-color: #E0E0E0; margin-top: 40px;"></div>
    <div class="prices d-block text-center mt-4 mb-3">
        <div class="row">
            <div *ngIf="!isLoading" class="col-12 col-md-6 offset-md-3">
                <div>
                    <p style="font-weight: 550; font-size: 16px;" class="mb-2">Payment details:</p>
                    <div class="d-flex justify-content-between align-items-center">
                        <p style="font-weight: 400; text-align: start; font-size: 14px;" class="mb-0">Plan name: </p>
                        <p style="font-weight: 550; text-align: end; font-size: 16px;" class="mb-0 ml-auto">
                            {{selectedPlanName}}
                        </p>
                    </div>
                    <div class="d-flex justify-content-between align-items-center">
                        <p style="font-weight: 400; text-align: start; font-size: 14px;" class="mb-0">Subtotal: </p>
                        <p style="font-weight: 550; text-align: end; font-size: 16px;" class="mb-0 ml-auto">
                            {{selectedPlanSubtotal | valueFormat}} ({{currencyCode}})
                        </p>
                    </div>
                    <div class="d-flex justify-content-between align-items-center">
                        <p style="font-weight: 400; text-align: start; font-size: 14px;" class="mb-0">Tax: </p>
                        <p style="font-weight: 550; text-align: end; font-size: 16px;" class="mb-0 ml-auto">
                            {{selectedPlanTax | valueFormat}} ({{currencyCode}})
                        </p>
                    </div>
                    <div class="d-flex justify-content-between align-items-center">
                        <p style="font-weight: 400; text-align: start; font-size: 14px;" class="mb-0"><u>Total: </u>
                        </p>
                        <p style="font-weight: 550; text-align: end; font-size: 16px;" class="mb-0 ml-auto">
                            <u>{{selectedPlanTotal | valueFormat}} ({{currencyCode}})</u>
                        </p>
                    </div>
                    <div class="d-flex justify-content-center align-items-center mt-2">
                        <img style="height: fit-content;" class="mr-2" src="assets/icons/money-back-icon.svg" />
                        <p class="plan-feature-text text-center d-flex mb-0">24-hour money back guarantee</p>
                    </div>
                </div>
            </div>
            <div *ngIf="isLoading" class="col-12 col-md-6 offset-md-3">
                <div>
                    <app-skeleton-loader [height]="'25px'" [linesNum]="4"></app-skeleton-loader>
                </div>
            </div>
        </div>

    </div>

    <div class='checkout-container'></div>
</mat-dialog-content>