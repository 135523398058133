<mat-dialog-content style="padding: 20px !important">
    <div class="d-flex position-relative">
        <img class="mx-auto" style="height: 40px;" src="assets/icons/logo-black-notext.png" />
        <img (click)="CloseModal()" class="my-auto position-absolute" style="height: 24px; right: 5px; cursor: pointer;"
            src="assets/icons/x-close.png" />
    </div>
    <h1 class="premium-title text-center mb-1 mt-4">
        <u>Unlimited</u> Access to the <u>Youtube Ad & Lander Library</u> - Start Your Free Premium Trial Now!
    </h1>
    <div class="row mx-0">
        <div class="col-12 offset-0 col-lg-8  offset-lg-2">
            <div class="plan-card plan-selected mt-5">
                <div class="d-flex align-items-center justify-content-center">
                    <p class="plan-title mb-0">24h Free Trial</p>
                </div>
                <p class="plan-subtitle mt-2 mb-1 text-center">Premium monthly</p>
                <p class="plan-feature-text text-center"><u>24h free, then $67/mth + tax</u></p>
                <div class="plan-features mt-2">
                    <div class="d-block">
                        <div class="d-flex">
                            <img style="height: fit-content;" class="mr-2" src="assets/icons/green-check.png" />
                            <p class="plan-feature-text">Full access: 21M+ ads, 5M+ landers</p>
                        </div>
                        <div class="d-flex">
                            <img style="height: fit-content;" class="mr-2" src="assets/icons/green-check.png" />
                            <p class="plan-feature-text">Detailed performance metrics</p>
                        </div>
                        <div class="d-flex">
                            <img style="height: fit-content;" class="mr-2" src="assets/icons/green-check.png" />
                            <p class="plan-feature-text">Advanced ad-sorting options</p>
                        </div>
                        <div class="d-flex">
                            <img style="height: fit-content;" class="mr-2" src="assets/icons/green-check.png" />
                            <p class="plan-feature-text">Youtube ads training library</p>
                        </div>
                    </div>
                    <button (click)="isCheckoutOpen = !isCheckoutOpen" class="btn sign-in-button mt-3">Get
                        started</button>
                </div>
            </div>
        </div>
    </div>
    <div [ngClass]="{'display-toggle': !isCheckoutOpen}">
        <div style="height: 1px; background-color: #E0E0E0; margin-top: 40px;"></div>
        <div class="prices d-block text-center mt-4 mb-3">
            <div class="row">
                <div class="col-12 col-md-6 offset-md-3">
                    <div>
                        <p style="font-weight: 550; font-size: 16px;" class="mb-2">Payment details:</p>
                        <div class="d-flex justify-content-between align-items-center">
                            <p style="font-weight: 400; text-align: start; font-size: 14px;" class="mb-0">Plan name:
                            </p>
                            <p style="font-weight: 550; text-align: end; font-size: 16px;" class="mb-0 ml-auto">
                                {{selectedPlanName}}
                            </p>
                        </div>
                        <div class="d-flex justify-content-between align-items-center">
                            <p style="font-weight: 400; text-align: start; font-size: 14px;" class="mb-0">Subtotal: </p>
                            <p style="font-weight: 550; text-align: end; font-size: 16px;" class="mb-0 ml-auto">
                                {{selectedPlanSubtotal | valueFormat}} ({{currencyCode}})
                            </p>
                        </div>
                        <div class="d-flex justify-content-between align-items-center">
                            <p style="font-weight: 400; text-align: start; font-size: 14px;" class="mb-0">Tax: </p>
                            <p style="font-weight: 550; text-align: end; font-size: 16px;" class="mb-0 ml-auto">
                                {{selectedPlanTax | valueFormat}} ({{currencyCode}})
                            </p>
                        </div>
                        <div class="d-flex justify-content-between align-items-center">
                            <p style="font-weight: 400; text-align: start; font-size: 14px;" class="mb-0"><u>Total: </u>
                            </p>
                            <p style="font-weight: 550; text-align: end; font-size: 16px;" class="mb-0 ml-auto">
                                <u>{{selectedPlanTotal | valueFormat}} ({{currencyCode}})</u>
                            </p>
                        </div>
                        <div class="d-flex justify-content-center align-items-center mt-2">
                            <img style="height: fit-content;" class="mr-2" src="assets/icons/money-back-icon.svg" />
                            <p class="plan-feature-text text-center d-flex mb-0">24-hour money back guarantee</p>
                        </div>
                    </div>
                </div>
            </div>

        </div>
        <div id="checkout-container" class='checkout-container'></div>
    </div>
</mat-dialog-content>