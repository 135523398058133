<section class="p-3">
    <div class="d-flex justify-content-between">
        <div class="d-block">
            <h4 class="account-section-title">Billing info</h4>
            <p class="section-desc">Click the button to update your billing info.</p>
        </div>
        <button (click)="UpdateBillingInfo()" style="height: fit-content;"
            class="btn light-blue-button  mt-3 mr-3">Update</button>
    </div>
    <!-- <div class="section-underline"></div>
    <h4 class="account-section-title mt-3">Invoices</h4>
    <p class="section-desc">Access all your invoices in the table below.</p>
    <div class="section-underline"></div>
    <div class="row mt-3">
        <div class="col-12 col-md-3">
            <p class="field-label mb-1">Billing history</p>
            <p class="section-desc">
                For any questions, please feel free to contact our support team at support&#64;vidtao.com.
            </p>
        </div>
        <div class="col-12 col-md-9">
            <section class="p-0 overflow-hidden table-section mb-5">
                <table>
                    <thead style="border-top: none">
                        <tr>
                            <th class="text-center">Invoice</th>
                            <th>Status</th>
                            <th>Amount</th>
                            <th>Plan</th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let invoice of invoices">
                            <td class="text-centered" data-label="Invoice">{{invoice.paymentDate | dateFormat}}</td>
                            <td data-label="Status">
                                <div *ngIf="invoice.isPaid" class="d-flex status-container paid-status">
                                    <i class='bx bx-check' style="font-size: 20px;"></i>
                                    <span style="margin-right: 5px;">Paid</span>
                                </div>
                            </td>
                            <td data-label="Amount"><strong>{{invoice.amount | valueFormat}}</strong>
                                ({{invoice.currency}})</td>
                            <td data-label="Plan">{{invoice.planName}}</td>
                            <td data-label="Affiliate">
                                <button class="btn">
                                    <span (click)="PreviewSelectedInvoice(invoice)"
                                        style="font-weight: 600; color: #3538CD;" class="section-desc">View</span>
                                </button>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </section>

        </div>

    </div> -->
</section>