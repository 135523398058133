<section *ngIf="paymentPlan && paymentPlan?.plan !== 'free'" class="p-3">
    <div class="d-flex">
        <div class="d-block">
            <h4 class="account-section-title">Current plan</h4>
            <p class="section-desc">Your plan renews on {{paymentPlan?.nextPayment | dateFormat}}.</p>
        </div>
        <div class="d-flex flex-column ml-auto mr-3">
            <button (click)="CancelPlan()" style="height: fit-content;" class="btn red-button">Cancel
                plan</button>
            <button [ngStyle]="{'display': paymentPlan?.plan === 'yearly' ? 'none' : 'flex'}"
                (click)=" UpgradeToYearly()" style="height: fit-content; font-size: 14px;" class="btn main-button my-3">
                <div [ngClass]="!isProcessing ? 'hide-spinner-2': ''"
                    style="height: 21px; width: 21px; border-top-color: white" class="spinner mr-2"></div>
                Upgrade to yearly
            </button>
        </div>
    </div>

    <div class="section-underline"></div>
</section>