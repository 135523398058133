import { Injector } from "@angular/core";
import { environment } from "src/environments/environment";
import {
    Chart,
    PointElement,
    ArcElement,
    CategoryScale,
    LinearScale,
    LineController,
    LineElement,
    TimeScale,
    Title,
    Tooltip,
    BarElement,
    BarController,
    DoughnutController
} from 'chart.js';



export async function appInit(injector: Injector) {

    if (environment.paddleSandbox) {
        // @ts-ignore
        window.Paddle.Environment.set('sandbox');
    }
    // window.Paddle.Setup({ vendor: environment.vendorId }); //classic
    // @ts-ignore
    window.Paddle.Initialize({
        token: environment.clientSidePaddleToken,
        checkout: {
            settings: {
                displayMode: "inline",
                frameTarget: "checkout-container",
                frameInitialHeight: "450",
                frameStyle: "width: 100%; min-width: 312px; background-color: transparent; border: none;"
            }
        }
    }) //billing

    //Initialize chart
    Chart.register(PointElement,
        CategoryScale,
        LinearScale,
        LineController,
        LineElement,
        BarElement,
        BarController,
        DoughnutController,
        ArcElement,
        TimeScale,
        Title,
        Tooltip);
    Chart.defaults.font.size = 14;
    Chart.defaults.font.family = 'Inter';
    return Promise.resolve();
}

