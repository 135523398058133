import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  UrlTree,
  Router,
} from '@angular/router';
import { Observable, pipe } from 'rxjs';
import { map, switchMap, take } from 'rxjs/operators';
import { ModuleType } from 'src/app/models/enums';
import { StateService } from 'src/app/services/shared/state.service';

@Injectable({
  providedIn: 'root',
})
export class NotLoggedInGuard {
  constructor(private stateService: StateService, private router: Router) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ):
    | boolean
    | UrlTree
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree> {
    if (state.url.includes('openRegister=true')) {
      this.stateService.openRegisterModal = true;
    } else if (state.url.includes('openLogin=true')) {
      this.stateService.openLoginModal = true;
    }
    return this.stateService.user$.pipe(
      map((user) => {
        if (user) {
          if (sessionStorage.getItem('moduleType') == ModuleType.Affiliate)
            return this.router.createUrlTree(['/dashboard/affiliate-ads']);
          //if logged in, redirect to dashboard
          else return this.router.createUrlTree(['/dashboard']); //if logged in, redirect to dashboard
        }
        return true;
      })
    );
  }
}
