import { Component, OnInit } from '@angular/core';
import { PaymentPlan } from 'src/app/models/paymentPlan';
import { ApiService } from 'src/app/services/api/api.service';
import { PopupService } from 'src/app/services/shared/popup.service';
import { StateService } from 'src/app/services/shared/state.service';

@Component({
  selector: 'app-subscription-plan',
  templateUrl: './subscription-plan.component.html',
  styleUrls: ['./subscription-plan.component.scss'],
})
export class SubscriptionPlanComponent implements OnInit {
  // @ts-ignore
  paddle = Paddle;
  paymentPlan!: PaymentPlan;
  isProcessing: boolean = false;
  email: string | undefined = '';
  constructor(
    public apiService: ApiService,
    public stateService: StateService,
    public popupService: PopupService,
  ) {
    this.email = this.stateService.user?.email;
  }

  async ngOnInit(): Promise<void> {
    await this.LoadCurrentPlan();
  }

  public async LoadCurrentPlan() {
    const result = await this.apiService.GetCurrentPlan();

    if (result?.errorMessage) this.popupService.Notify(result.errorMessage);

    if (result?.data) this.paymentPlan = result?.data;
  }

  public async CancelPlan() {
    this.popupService.AskToConfirm(
      'Subscription cancellation',
      'Are you sure that you want to cancel subscription plan?',
      () => {
        if (!this.paymentPlan.subscriptionId.includes('sub_')) {
          window.open(this.paymentPlan.cancelUrl, '_blank');
        } else {
          this.paddle.Retain.initCancellationFlow({
            subscriptionId: this.paymentPlan.subscriptionId,
          });
        }
      },
    );
  }

  public async UpgradeToYearly() {
    this.popupService.AskToConfirm(
      'Upgrade account',
      'Are you sure that you want to upgrade to yearly plan?',
      async () => {
        this.isProcessing = true;
        const res = await this.apiService.UpgradeToYearly(this.email);
        if (res?.errorMessage) {
          this.popupService.Notify(res.errorMessage);
          return;
        }
        if (res?.data) {
          this.popupService.Notify(
            'Successfully changed plan to yearly subscription.',
          );
          this.LoadCurrentPlan();
          const editedUser = await this.apiService.GetUser();
          if (editedUser) this.stateService.UpdateUser(editedUser.data);
        }
        this.isProcessing = false;
      },
    );
  }
}
