import { Component, Inject, OnInit } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { GetPaymentPlans } from 'src/app/models/getPaymentPlans';
import { ApiService } from 'src/app/services/api/api.service';
import { StateService } from 'src/app/services/shared/state.service';
import { StaticDataService } from 'src/app/services/shared/static.data.service';
import { UserPurchasedGtm } from 'src/app/utils/gtm';

@Component({
  selector: 'app-trial-subscription',
  templateUrl: './trial-subscription.component.html',
  styleUrls: ["../login/login.component.scss"],
})
export class TrialSubscriptionComponent implements OnInit {
  // @ts-ignore
  paddle = Paddle;
  availablePlans?: GetPaymentPlans;
  email: string | null = null;
  userId: string | null = null;
  selectedPlanName: string = '';
  selectedPlanSubtotal: number = 0;
  selectedPlanTotal: number = 0;
  selectedPlanTax: number = 0;
  currencyCode: string = ''
  isCheckoutOpen: boolean = false;


  constructor(public dialogRef: MatDialogRef<TrialSubscriptionComponent>,
    private afAuth: AngularFireAuth,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private apiService: ApiService,
    private stateService: StateService,
    public staticDataService: StaticDataService) {
    this.availablePlans = this.staticDataService.paymentPlans;
  }

  async ngOnInit() {
    this.afAuth.currentUser.then((user) => {
      if (user) {
        this.email = user.email;
        this.userId = user.uid;
        this.OpenSubscriptionsCheckout();
      }
    });
  }

  public OpenSubscriptionsCheckout() {
    let itemsList = [
      {
        priceId: this.availablePlans?.monthly24HTrial.planPriceId,
        quantity: 1
      }
    ];
    this.paddle.Update({
      eventCallback: (event: any) => this.HandlePaddleEvent(event),  // Use arrow function
    });
    this.paddle.Checkout.open({
      items: itemsList,
      customData: {
        userId: this.userId,
        email: this.email
      },
      customer: {
        email: this.email
      }
    });
  }

  HandlePaddleEvent(event: any) {
    console.log(event);
    switch (event.name) {
      case "checkout.loaded":
        this.selectedPlanName = event.data.items[0].product.name;
        this.selectedPlanSubtotal = event.data.items[0].recurring_totals.subtotal;
        this.selectedPlanTotal = event.data.items[0].recurring_totals.total;
        this.selectedPlanTax = event.data.items[0].recurring_totals.tax;
        this.currencyCode = event.data.currency_code;
        break;
      case "checkout.completed":
        console.log("checkout.completed", event);
        this.GetUpdatedUser(event);
        break;
    }
  }



  public async GetUpdatedUser(event: any) {
    var user = await this.apiService.SubscribeUser(event);
    while (user?.data?.plan === "free") {
      await this.Sleep(200);
      user = await this.apiService.SubscribeUser(event);
    }
    this.stateService.UpdateUser(user?.data);
    this.CloseModal();
  }

  Sleep(milliseconds: number): Promise<void> {
    return new Promise<void>((resolve) => {
      setTimeout(resolve, milliseconds);
    });
  }

  public CloseModal() {
    this.dialogRef.close();
  }
}

